import React from 'react';
import { Article } from './types/Article';
import { Default } from './types/Default';
import { Quote } from './types/Quote';
import { Large } from './types/Large';
import { Simple } from './types/Simple';
import { FullWidth } from './types/FullWidth';
import { Vertical } from './types/Vertical';
import { Hub } from './types/Hub';

const componentMap = (props, components) => {
  const fallback = components.default || null;
  const matchedKey = Object.keys(props).find(key => {
    if (components[key]) return key;
  });
  return components[matchedKey] || fallback;
};

export const Carousel = props => {
  const Component = componentMap(props, {
    article: Article,
    quote: Quote,
    large: Large,
    simple: Simple,
    fullWidth: FullWidth,
    vertical: Vertical,
    default: Default,
    hub: Hub
  });

  return <Component {...props} />;
};

Carousel.displayName = 'Carousel';
