import React from 'react';
import PropTypes from 'prop-types';

export const Col = ({ children, addClass, noPadding, justify, alignItems, direction }) => (
  <div
    className={`col__container ${addClass ? `${addClass}` : ''} col__container--${noPadding ? 'noPadding' : 'padded'}`}
    style={{
      display: justify ? 'flex' : 'block',
      justifyContent: justify ? justify : 'flex-start',
      alignItems: alignItems ? alignItems : 'flex-start',
      flexDirection: direction ? direction : 'column'
    }}
  >
      {children}
  </div>
);

Col.propTypes = {
  children: PropTypes.any,
  addClass: PropTypes.string,
  direction: PropTypes.string,
  justify: PropTypes.string,
  alignItems: PropTypes.string,
  noPadding: PropTypes.bool
}