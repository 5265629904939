import React, { Fragment, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Social from '../../../../components/social/social';
import ShareIcon from './ShareIcon';

export const ShareButtonRedesign = ({textToShare, uriToShare}) => {
  const [isOpen, setOpen] = useState(false);
  const socialListRef = useRef(null);

   // Close share menu if user clicks outside of it
   useEffect(
    () => {
      const checkIfOutsideClick = e => {
        const clickWasOutside = socialListRef.current &&
        !socialListRef.current.contains(e.target);

        if (clickWasOutside) {
          // Close share menu
          setOpen(false);
          document.removeEventListener('mousedown', e => checkIfOutsideClick(e));
        }
      };

      // Attach event listener
      document.addEventListener('mousedown', e => checkIfOutsideClick(e));

      // Detach listener on unmount
      return () => {
        document.removeEventListener('mousedown', e => checkIfOutsideClick(e));
      };
    },
    [socialListRef]
  );

  return (
    <Fragment>
      <button ref={socialListRef} className="redesign-share-btn">
        <div className="shareBtnRedesignContainer"
          onClick={() => setOpen(true)}
        >
          {!isOpen && (
            <Fragment>
              <span>SHARE</span>
              <ShareIcon />
            </Fragment>
          )}
        </div>
        {isOpen && (
        <div className="social-box">
          <Social
            modifier="StickyBar"
            disableStyle={true}
            hideRecirc={true}
            text={encodeURI(textToShare)}
            uri={encodeURI(uriToShare)}
            redesign={true}
            onShare={() => setOpen(false)}
          />
        </div>
      )}
      </button>
    </Fragment>
  );
}

ShareButtonRedesign.propTypes = {
  textToShare: PropTypes.string.isRequired,
  uriToShare: PropTypes.string.isRequired,
}