import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tab } from './Tab/Tab';

export const Tabs = ({ children, actions, currentTab, setActiveTab }) => {
  return (
    <div className={`tabs__container`}>
      <div className="tabs__list">
        {children.map((child, i) => {
          const { label } = child.props;

          return (
            <Tab
              key={label}
              activeTab={children[currentTab].props.label}
							setActiveTab={(label) => {
                setActiveTab(children.findIndex(child => child.props.label === label));
              }}
							actions={actions}
							i={i}
              {...child.props}
            />
          );
        })}
      </div>
      <div className={`tab-content tab-${currentTab}`}>
        {children.map((child) => {
          if (child.props.label !== children[currentTab].props.label) return undefined;
          return child.props.children;
        })}
      </div>
    </div>
  );
}

Tabs.propTypes = {
  data: PropTypes.array,
  height: PropTypes.string,
  children: PropTypes.any,
	actions: PropTypes.object,
	activeTab: PropTypes.string,
	setActiveTab: PropTypes.func,
};
