import React, { Component } from "react";
import Slider from "react-slick";

export const Large = ({ children }) => {
  const settings = {
    dots: true,
    className: "carousel__large",
    centerMode: true,
    infinite: true,
    centerPadding: "240px",
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplaySpeed: 5000,
    autoplay: true,
    dotsClass: "large__dots",
    customPaging: function(i) {
      return (
        <a>&nbsp;</a>
      );
    },
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          centerPadding: "180px",
        }
      },
      {
        breakpoint: 1440,
        settings: {
          centerPadding: "130px",
        }
      },
      {
        breakpoint: 1250,
        settings: {
          centerPadding: "80px",
        }
      },
      {
        breakpoint: 1024,
        settings: {
          centerPadding: "50px",
        }
      },
      {
        breakpoint: 900,
        settings: {
          centerPadding: "40px",
        }
      },
      {
        breakpoint: 640,
        settings: {
          centerPadding: "40px",
        }
      },
      {
        breakpoint: 480,
        settings: {
          centerPadding: "20px",
        }
      }
    ]
  };
  return (
    <div>
      <Slider {...settings}>
       {children}
      </Slider>
    </div>
  );
}

// small: 0,
// medium: 640px,
// large: 1024px,
// xlarge: 1200px,
// xxlarge: 1440px,
// micLarge: 1125px,