import React from 'react';
import PropTypes from 'prop-types';

export const Grid = ({ children }) => (
  <div className="grid__container">{children}</div>
);

Grid.propTypes = {
  children: PropTypes.any
}
