import PropTypes from 'prop-types';

export const List = ({ template, items }) => {
  return items.map(item => template ? template(item) : item);
};

List.propTypes = {
  template: PropTypes.func.isRequired,
  items: PropTypes.array
}
