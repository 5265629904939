import React from 'react';
import PropTypes from 'prop-types';
import { Image } from '../Image/Image';
import { Caption } from '../Caption/Caption';
// import './ProfileCard.scss';

export const ProfileCard = ({ image, mobile_image, title, subtitle, link, index, noBorder, mobile, onItemClick }) => (
  <div className="profile_card--container">
    <a
      href={link}
      target="_blank"
      rel="noreferrer"
      onClick={() => {
        onItemClick(title, index);
      }}
    >
    <div className="profile_card--image">
      <Image
        image={mobile && mobile_image ? mobile_image : image}
        noBorder={noBorder}
      />
    </div>
    <div className="profile_card--meta" >
      <Caption title={title} subtitle={subtitle} />
    </div>
    </a>
  </div>
);

ProfileCard.propTypes = {
  image: PropTypes.string,
  mobile_image: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  link: PropTypes.string,
  noBorder: PropTypes.bool,
  mobile: PropTypes.bool,
  index: PropTypes.any,
  onItemClick: PropTypes.any
};

ProfileCard.defaultProps = {
  image: 'https://images.fastcompany.net/image/upload/v1625086207/fcweb/example_profile_card_utr08f.jpg',
  mobile_image: 'https://images.fastcompany.net/image/upload/v1625086207/fcweb/example_profile_card_utr08f.jpg',
  title: 'Default Title Placeholder',
  subtitle: 'Default Subtitle Placeholder',
  link: '',
  noBorder: false,
  mobile: false
};
