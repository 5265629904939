// Component: MultiTabList
//
// ACTIONS
// -------
// onTabClick:
// e:	Event object
// label: Tab text label
// i: Index
//
// onRowClick:
// e:	Event object
// row: Row data object
// i: Index

import React from 'react';
import PropTypes from 'prop-types';
import { Tabs } from './Tabs/Tabs';
import { Table } from '../Table/Table';
// import { List } from '../List/List';
// import { Image } from '../Image/Image';

export const MultiTabList = ({ list, data, actions, display = "table", interstitialUnit, interstitialFreq, interstitialFreqAlt = null, activeTab, setActiveTab }) => {
  return (
    <div className={`multi-tab-list ${list}`}>
      <Tabs actions={actions} currentTab={activeTab} setActiveTab={setActiveTab}>
        {data.map((tab, i) => {
          return (
            <div
              key={`tab-list-item-${i}`}
              label={tab.category}
              image={tab.image}
            >
              <Table
                interstitialUnit={interstitialUnit}
                interstitialFreq={interstitialFreqAlt && i === 1 ? interstitialFreqAlt : interstitialFreq}
                listItems={tab.data}
                displayfields={tab.fields}
                type={tab.type}
                behavior={tab.behavior}
                showHeader={tab.showHeader}
                actions={actions}
                activeIndex={i}
              />
            </div>
          )
        })}
      </Tabs>
    </div>
  );
}

MultiTabList.propTypes = {
  name: PropTypes.string,
  display: PropTypes.string,
  data: PropTypes.array.isRequired,
	actions: PropTypes.shape({
		onTabClick: PropTypes.func,
		onRowClick: PropTypes.func
	}),
};
