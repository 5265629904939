import React from 'react';
import PropTypes from 'prop-types';
import { Text, HR } from '../'

export const IconTitle = ({ icon, title, bold, color, underline, underlineColor = "#000000" }) => (
  <div className={`icon_title--container ${underline ? 'with-underline' : ''}`}>
    <div className="icon_title--icon">
      <img alt="" src={`${icon}`} />
    </div>
    <div className={`icon_title--title ${icon ? 'with-icon' : ''}`}>
      <Text
        as="h2"
        color={color || '#000000'}
        fontSize="30px"
        fontFamily="CentraNo2" //css being overrided by FC general text css
        fontWeight={bold ? 700 : 400}
      >
        {title}
      </Text>
      {underline && (
        <HR height="1px" marginClass="mtb-sm" color={underlineColor} />
      )}
    </div>
  </div>
);

IconTitle.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  bold: PropTypes.bool,
  color: PropTypes.string,
  underline: PropTypes.bool,
  underlineColor: PropTypes.bool
};
