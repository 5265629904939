import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  H1,
  H2,
  Spacer
} from '../';
import { Separator } from '../Seperator/Separator';

export const FranchiseTopper = ({ headline, subHeadline, allCaps, alt, separator }) => {
  return (
    <header className="franchiseTopper__container">
      <H1 textAlign="center" className={alt ? 'alt' : ''}>{headline}</H1>
      <Spacer height="10px" />
      <div
        className={`subHeadline subHeadline--${allCaps ? 'allCaps' : 'normal'} ${alt ? 'subHeadline--alt' : ''}`}
        dangerouslySetInnerHTML={{__html: subHeadline}}
      />
      {separator && (
        <Fragment> 
          <Spacer height="20px" />
          <Separator width="35px" margin="0 auto" />
        </Fragment>
      )}
    </header>
  )
};