import React from 'react';
import {
  Button,
} from "..";

export const Promotion = ({ promoImage, brandImage, promoDate, promoDescription, promoLabel, promoUrl = "#", overrideLabel='', bgColor='#EF5B24', hovercolor="#000000", labelHoverColor="#ffffff"}) => {
  return (
    <div className="promotion__container">
      <div className="promotion__image">
        <img src={promoImage} />
      </div>
      <div className="promotion__description">
        {brandImage && (
          <div className="promotion__branding"></div>
        )}
        <div className="promotion__description__time">
          {promoDate}
        </div>
        <div className="promotion__description__text">
          {promoDescription}
        </div>
        <div className="promotion__button">
          <a target="_blank" href={promoUrl}>
            <Button rounded label={overrideLabel ? overrideLabel : "Get Tickets"} bgColor={bgColor} hoverColor={hovercolor} labelHoverColor={labelHoverColor} />
          </a>
        </div>
      </div>
    </div>
  );
}