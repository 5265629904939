import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

export const Tab = ({ label, image, activeTab, setActiveTab, actions, i }) => {	
  return (
    <div
      className={`tab ${label === activeTab ? 'active' : 'inactive'} tab-index-${i}`}
      onClick={(e) => {
				setActiveTab(label);
				if(get(actions, 'onTabClick')) {
          actions.onTabClick(e, label, i);
        }
			}}
    >
      {label && (
        <div className={`category`}>{label}</div>
      )}
      {image && (
        <img src={image} />
      )}
    </div>
  );
}

Tab.propTypes = {
	image: PropTypes.string,
  label: PropTypes.string,
  activeTab: PropTypes.string,
	setActiveTab: PropTypes.func,
  onClick: PropTypes.func,
	actions: PropTypes.shape({
		onTabClick: PropTypes.func
	}),
	i: PropTypes.number
};

Tab.defaultProps = {
  height: '150px'
}
