import React from 'react';
import { Button } from '../';

export const Quote = ({ quoteText, quoteAttribution, quoteLink, align = "center" }) => {
  return (
    <div className={`quote quote__container ${align}`}>
      <figure className="quote__figure">
        <blockquote>
            <p>{quoteText}</p>
        </blockquote>
        <figcaption>—{quoteAttribution}</figcaption>
      </figure>
      {quoteLink && (
        <a
          className="quote__link"
          rel="noreferrer"
          target="_blank"
          href={quoteLink}
        >
          <Button rounded label="READ MORE" bgColor="#EF5B24" labelHoverColor="#ffffff" />
        </a>
      )}
    </div>
  )
}