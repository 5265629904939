import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

export const Caption = ({ title, subtitle, link }) => {
  return (
    <Fragment>
      <div className="caption-container">
        <div className="caption-container--title">{title}</div>
        <div className="caption-container--subtitle" dangerouslySetInnerHTML={{ __html: subtitle }} />
        {link && (
          <a href={link}>
            <img
              className="caption-link-arrow"
              alt=""
              src="https://images.fastcompany.net/image/upload/v1625248831/fcweb/arrow-triangle_lsirzq.svg"
            />
          </a>
        )}
      </div>
    </Fragment>
  )
}

Caption.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  link: PropTypes.string
}

Caption.defaultProps = {
  title: 'Default Caption Title',
  subtitle: 'Default Caption Subtitle can contain <a href="">links</a> and stuff!',
}
