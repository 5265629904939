import React from 'react';
import PropTypes from 'prop-types';

export const H1 = ({ children, className, ...props }) => {
  return (
    <h1
      className={`h1 ${className || ''}`}
      style={{...props}}
    >
      {children}
    </h1>
  )
};