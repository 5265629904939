import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

export const CategoryAndArticles = ({ icon, category_name, category_url, articles }) => {
  const [open, setOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(null);

  useEffect(() => {
    let mql = window.matchMedia('(max-width: 639px)');
    
    mql.addEventListener('change', () => {
      setIsMobile(mql.matches);
      setOpen(false);
    });
    
    setIsMobile(mql.matches);
  }, []);

  const Arrow = ({ status }) => (
    <svg xmlns="https://www.w3.org/2000/svg" width="9" height="6" viewBox="0 0 9 6" className={status}>
      <path d="M559.3,726.9a10.746,10.746,0,0,0-2.215-3.867A16.468,16.468,0,0,0,554.8,720.9h9a16.46,16.46,0,0,0-2.284,2.133A10.746,10.746,0,0,0,559.3,726.9" transform="translate(-554.8 -720.9)"/>
    </svg>
  );

  return (
    <div className="category-and-articles">
      <div className="category-and-articles__category">
        <img src={icon} />

        <div className="category-and-articles__category-name">
          {category_name}
        </div>

        <div className="category-and-articles__category-arrow" />

        <a rel="noopener" target="_blank" href={category_url} />
      </div>

      {isMobile &&
        <div className="category-and-articles__toggle" onClick={() => setOpen(!open)}>
          {open ? 'HIDE' : 'SHOW'} LIST <Arrow status={open ? 'hide' : 'show'} />
        </div>
      }

      <div className="category-and-articles__articles">
        {((isMobile && open) || !isMobile) && articles.map(article => (
          <div className="category-and-articles__articles-article" key={article.hed}>
            <div className="category-and-articles__articles-article-hed">
              {article.hed}
            </div>

            <div className="category-and-articles__articles-article-dek" dangerouslySetInnerHTML={{__html: article.dek}} />

            <a rel="noopener" target="_blank" href={article.url} />
          </div>
        ))}
      </div>
    </div>
  );
}

CategoryAndArticles.propTypes = {
  icon: PropTypes.string,
  category_name: PropTypes.string.isRequired,
  category_url: PropTypes.string,
  articles: PropTypes.shape([]).isRequired
}