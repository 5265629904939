import React, {useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { Arrow } from '../Arrow';
import { get, set } from 'lodash';

export const Quote = ({ children, speed }) => {
  const settings = {
    className: 'carousel__quote',
    // mobileFirst: true,
    swipeToSlide: true,
    dots: false,
    infinite: true,
    slide: 'carouselQuote',
    speed: speed || 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 639,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      }
    ],
    centerMode: false,
    customPaging: function pagingStyles() {
      return <div className="carousel__paging" />
    },
    dotsClass: 'carousel__dots',
    appendDots: function dotStyles(dots) {
      return <ul> {dots} </ul>;
    },
    nextArrow: <Arrow classOverride="arrow-next line" direction="next" />,
    prevArrow: <Arrow classOverride="arrow-prev line" direction="prev" />
  };

  // If no Carousel items, don't render a carousel component
  if (!children) return false;

  const sliderRef = useRef(null);

  const setHeights = () => {
    let maxHeight = 0;
    get(sliderRef, 'current.innerSlider.list.children[0].children', []).forEach(
      ele => {
        maxHeight = Math.max(
          maxHeight,
          get(ele, 'children[0].offsetHeight')
        );
      }
    );
    if (maxHeight > 0){
      get(sliderRef, 'current.innerSlider.list.children[0].children', []).forEach(
        ele => {
          set(
            ele,
            'children[0].style.height',
            `${maxHeight}px`
          );
        }
      );
    }
  }

  // to make sure card items are same height per carousel
  useEffect( () => {
    setTimeout(setHeights, 1000);
    window.addEventListener('resize', setHeights)

    return () => window.removeEventListener('resize', setHeights);
  }, []);

  return (
    <Slider ref={sliderRef} {...settings}>
      {children.map((child, i) => (
        <div
          key={`carousel-item-${i}`}
          className="carousel__item"
        >
          {child}
        </div>
      ))}
    </Slider>
  );
};

Quote.propTypes = {
  children: PropTypes.node.isRequired,
  speed: PropTypes.number,
  slidesToShow: PropTypes.number,
  slidesToScroll: PropTypes.number
};

Quote.defaultProps = {
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};
