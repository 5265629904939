import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { IconTitle } from '../IconTitle/IconTitle';
import { LeftRailItem } from './LeftRailItem/LeftRailItem';

// const leftRailItems = [
//   {
//     eyeBrowText: "Intro",
//     textItem: "Lorem ipsum dolor sit amet consetetur sadipscing elitr, sed diam Placeholder",
//   },
//   {
//     eyeBrowText: "EYEBROW",
//     textItem: "Lorem ipsum dolor sit amet consetetur sadipscing elitr, sed diam",
//   },
//   {
//     eyeBrowText: "EYEBROW",
//     textItem: "Lorem ipsum dolor sit amet consetetur sadipscing elitr, sed diam",
//   },
//   {
//     eyeBrowText: "EYEBROW",
//     textItem: "Lorem ipsum dolor sit amet consetetur sadipscing elitr, sed diam",
//   },
//   {
//     eyeBrowText: "EYEBROW",
//     textItem: "Lorem ipsum dolor sit amet consetetur sadipscing elitr, sed diam",
//   },
//   {
//     eyeBrowText: "Default Title Placeholder",
//     textItem: "Default textItem Placeholder",
//   },
// ];

export const LeftRail = ({ children, color, maxWidth, noPadding, float, position, top, topIcon, topIconTitle, dataArray }) => {
  return (
    <section className={`section__leftRail_container ${noPadding ? 'noPadding' : ''} `}
      style={{ 
        backgroundColor: color,
        float: float,
        position: position,
        top: top
        }}>
      <div className='section__leftRail_inner' 
        style={{ maxWidth: maxWidth ? `${maxWidth}px` : 'intial' }}
      >
        {topIcon && <IconTitle  icon={topIcon} title={topIconTitle} />}
        {
          dataArray.map((item, idx) => {
            return (
              <LeftRailItem 
                maxWidth={'830'}
                eyeBrowColor={'grey'}
                eyeBrowText={get(item, 'eyeBrowText', '')} 
                textItem={get(item, 'textItem', '')}  
                key={`left_rail_item_${idx++}`}
              />
            )
          })
        }
        {children}
      </div>
    </section>
  );
};

LeftRail.propTypes = {
  color: PropTypes.string,
  children: PropTypes.any,
  maxWidth: PropTypes.number,
  float: PropTypes.string,
  position: PropTypes.string,
  setCurrColor: PropTypes.func,
  noPadding: PropTypes.bool,
  top: PropTypes.string,
  topIcon: PropTypes.string,
  topIconTitle: PropTypes.string,
  dataArray: PropTypes.array.isRequired
};

LeftRail.defaultProps = {
  maxWidth: null,
  noPadding: false,
  topIconTitle: null,
  dataArray: []
};