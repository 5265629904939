import React, {useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import Slider from "react-slick";
import { Arrow } from "../Arrow";
import { get, set } from "lodash";

export const Default = ({ children, speed, slidesToShow, slidesToScroll }) => {
  const settings = {
    className: "carousel__default",
    swipeToSlide: true,
    dots: true,
    infinite: true,
    speed: speed || 500,
    slidesToShow: slidesToShow || 1,
    slidesToScroll: slidesToScroll || 1,
    centerMode: true,
    centerPadding: "40px",
    customPaging: function pagingStyles() {
      return <div className="carousel__paging" />
    },
    dotsClass: 'carousel__dots',
    appendDots: function dotStyles(dots) {
      return <ul> {dots} </ul>;
    },
    nextArrow: <Arrow classOverride="arrow-next" direction="next" />,
    prevArrow: <Arrow classOverride="arrow-prev" direction="prev" />
  };

  // If no Carousel items, don't render a carousel component
  if (children.length <= 0) return null;
  const sliderRef = useRef(null);

  const setHeights = () => {
    let maxHeight = 0;
    get(sliderRef, 'current.innerSlider.list.children[0].children', []).forEach(
      ele => {
        maxHeight = Math.max(
          maxHeight,
          get(ele, 'children[0].offsetHeight')
        );
      }
    );
    if (maxHeight > 0){
      get(sliderRef, 'current.innerSlider.list.children[0].children', []).forEach(
        ele => {
          set(
            ele,
            'children[0].style.height',
            `${maxHeight}px`
          );
        }
      );
    }
  }

  // to make sure card items are same height per carousel
  useEffect( () => {
    setTimeout(setHeights, 1000);
    window.addEventListener('resize', setHeights)

    return () => window.removeEventListener('resize', setHeights);
  }, []);

  return (
    <Slider ref={sliderRef} {...settings}>
      {children.map((child, i) => (
        <div
          key={`carousel-item-${i}`}
          className="carousel__item"
        >
          {child}
        </div>
      ))}
    </Slider>
  );
};

Default.propTypes = {
  children: PropTypes.node.isRequired,
  speed: PropTypes.number,
  slidesToShow: PropTypes.number,
  slidesToScroll: PropTypes.number
};

Default.defaultProps = {
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};
