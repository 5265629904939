import React, {useEffect, useRef, useState} from 'react';

export const Tooltip = ({children, text, logo}) => {
  const dropdownRef = useRef(null);
  const [openTooltip, setOpenTooltip] = useState(false);
  const [tooltipAlign, setTooltipAlign] = useState('right');

  const toggle = () => {
    setOpenTooltip(prevState => !prevState);
  }

  const open = () => {
    setOpenTooltip(true);
  }

  const close = () => {
    setOpenTooltip(false);
  }

  useEffect(() => {
    if (!dropdownRef || !dropdownRef.current) return;

    const refRect = dropdownRef.current.getBoundingClientRect();

    if (!refRect.width) return;

    // calculate the right edge of dropdown,
    const refRightX = refRect.x + refRect.width;

    if (refRect.x < 0) {
      // if left edge goes outside of left side of screen
      setTooltipAlign('left');
    } else if (refRightX > window.innerWidth) {
      // if right edge goes outside of right side of screen
      setTooltipAlign('right');
    }
  }, [openTooltip])

  if (!children || !text) return null;

  return (
    <div className={`tooltip--wrapper ${tooltipAlign}`}>
      <div
        className="tooltip--label"
        onMouseEnter={open}
        onMouseLeave={close}
        onTouchEnd={toggle}
      >
        {children}
        {openTooltip && (
        <div className="tooltip--popup" ref={dropdownRef}>
            {logo && (
              <div className="tooltip--logo">
                <img src={logo} alt="tooltip logo" />
              </div>
            )}
            <div className="tooltip--text">
              {text}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
