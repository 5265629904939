import React from 'react';
import PropTypes from 'prop-types';

export const Arrow = ({ onClick, direction, classOverride }) => (
  <div
    className={`carousel-arrow ${classOverride || 'default'}`}
    onClick={onClick}
  >
    {!classOverride.includes('line') &&
      <img
        className={`carousel-${direction}-arrow`}
        alt={`carousel-${direction}-arrow`}
        src="https://images.fastcompany.net/image/upload/v1625248831/fcweb/arrow-triangle_lsirzq.svg"
      />
    }
  </div>
);

Arrow.propTypes = {
  onClick: PropTypes.func,
  direction: PropTypes.string.isRequired,
  classOverride: PropTypes.string
};

Arrow.defaultProps = {
  classOverride: undefined
};
