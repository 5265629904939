import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Carousel } from '../';

export const CardGrid = ({ mobileNoBorder, children }) => (
  <Fragment>
    <div className="cardgrid__container">
      {children}
    </div>
    <div className="cardgrid__mobile__container">
      <Carousel>
        { React.Children.map(children,
          child => {
            return React.cloneElement(child,
              {
                noBorder: mobileNoBorder,
                mobile: true
              }, null
            );
          }
        )}
      </Carousel>
    </div>
  </Fragment>
);

CardGrid.propTypes = {
  children: PropTypes.node.isRequired,
  mobileNoBorder: PropTypes.bool
};

CardGrid.defaultProps = {
  mobileNoBorder: false
};
