import React from 'react';
import PropTypes from 'prop-types';

export const Separator = (props) => {
  const defaultStyles = {
    borderTopWidth: '1px',
    borderTopStyle: 'solid',
    borderTopColor: '#ccc',
  }

  const dynamicStyles = {...props}

  return (
    <div
      className={`separator`}
      style={Object.assign({}, defaultStyles, dynamicStyles)}
    />
  );
};