import React, { Component, useEffect, useRef, useState } from "react";
import Slider from "react-slick";

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      onClick={onClick}
    >
      &lt;
    </div>
  );
}
const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      onClick={onClick}
    >
      &gt;
    </div>
  );
}

export const Hub = ({ children, onChangeIndex = () => {} }) => {
  const settings = {
    dots: false,
    className: "carousel__hub",
    centerMode: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    afterChange: (index) => {
      onChangeIndex(index);
    },
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
        }
      },
    ]
  };


  return (
    <div>
      <Slider
        {...settings}
      >
        {children}
      </Slider>
    </div>
  );
}
