import React from 'react';
import PropTypes from 'prop-types';

export const ListSelectorItem = ({ index, isActive, name, onClick }) => (
  <div
    key={`listselector-item-${index}`}
    className={`listselector__item listselector__item--${
      isActive ? 'active' : 'inactive'
    }`}
  >
    <button data-item-name={name} data-item-index={index} onClick={onClick}>
      {name}
    </button>
  </div>
);

ListSelectorItem.propTypes = {
  index: PropTypes.string,
  isActive: PropTypes.bool,
  name: PropTypes.string,
  onClick: PropTypes.func
};

ListSelectorItem.defaultProps = {
  index: '0',
  isActive: true,
  name: 'List Item',
  onClick: () => {
    console.log('item clicked!');
  }
};
