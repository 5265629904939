import React, { useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { ListSelectorItem } from './ListSelectorItem/ListSelectorItem';

export const ListSelector = ({ items, onItemClick, selectSection }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [activeItem, setActiveItem] = useState(0);

  const listItemClick = event => {
    const itemData = event.target.dataset;

    setActiveItem(parseInt(itemData.itemIndex));
    selectSection(itemData.itemName);
    if(onItemClick) {
      // Trigger any callbacks
      onItemClick(event);
    }
  }

  const toggleListSelector = () => {
    // Toggle display of menu items in selector
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="listselector__container">
      <div className="listselector__base__left">FOR</div>
      <div className="listselector__connector" />
      <div className="listselector__base__right" onClick={toggleListSelector}>
        <div className="listselector__item__container">
          {/* 1. ------- CUSTOM SELECT BOX -------- */}
          <div
            className={`listselector__menu listselector__menu${
              isExpanded ? '--open' : ''
            }`}
          >
            {items.map((item, i) => (
              <ListSelectorItem
                key={`list-selector-list-item-${i}`}
                index={`${i}`}
                isActive={i === activeItem}
                name={item.name}
                onClick={listItemClick}
              />
            ))}
          </div>
          <span>{get(items, `[${activeItem}].name`)}</span>
          <div
            className={`listselector__arrow listselector__arrow${
              isExpanded ? '--open' : 'closed'
            }`}
          >
            <img
              alt=""
              src="https://images.fastcompany.net/image/upload/v1625248831/fcweb/arrow-triangle_lsirzq.svg"
            />
          </div>

          {/* 2. -------- NATIVE SELECT BOX ------- */}
          {/* <select className="listselector__select">
            {items.map((item, i) => (
              <option value={`${i}`}>{item.name}</option>
            ))}
          </select> */}
        </div>
      </div>
    </div>
  );
};

ListSelector.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})),
  onItemClick: PropTypes.func,
  selectSection: PropTypes.func
};

ListSelector.defaultProps = {
  items: [
    {
      name: 'First Item',
    },
    {
      name: 'Second Item',
    },
    {
      name: 'Third Item',
    },
  ],
  onItemClick: () => {},
  selectSection: () => {}
}
