import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '../../Text/Text';

export const LeftRailItem = ({ eyeBrowColor, maxWidth, eyeBrowText, textItem}) => {
  return (
    <div className='leftRailItem_container'
      style={{maxWidth: maxWidth ? `${maxWidth}px` : 'intial'}}
    >
      <Text
        color={eyeBrowColor ? `${eyeBrowColor}` : ''}
        as="li"
      >
        {eyeBrowText}
      </Text>
      <Text
        color="#000000"
        as="p"
      >
        {textItem}
      </Text>
      <hr className={`border`}></hr>
    </div>
  );
};

LeftRailItem.propTypes = {
  eyeBrowColor: PropTypes.string,
  maxWidth: PropTypes.number,
  eyeBrowText: PropTypes.string,
  textItem: PropTypes.string,
};

LeftRailItem.defaultProps = {
  maxWidth: null,
  eyeBrowText: null
};