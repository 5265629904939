import React from "react";
import PropTypes from "prop-types";

export const HR = ({ color, height, marginClass }) => (
  <hr
    className={`horizontalRule ${marginClass? marginClass : ''}`}
    style={{
      color: color || '#000000',
      backgroundColor: color || '#000000',
      height: height || 5,
    }}
  />
);

HR.propTypes = {
  color: PropTypes.string,
  marginClass: PropTypes.string,
  height: PropTypes.number,
}

HR.defaultProps = {
  height: 5,
  color: '#000000',
}