import React from 'react';

export const FranchiseVertical = ({hed, subHed, verticalImg, verticalImgMobile, caption, isVideo, titleIcon}) => {
  return (
    <div className="franchise_vertical_container">
      <div className="main_content">
        <div className="text_column">
          {titleIcon ? <img src={titleIcon}></img> : <></>}
          <h1> {hed}</h1>
          <h2> {subHed} </h2>
        </div>
        <div className="image_column">
          {isVideo ?
            <video autoPlay muted loop>
              <source
                media="(max-width: 600px)"
                src={verticalImgMobile}
                type="video/mp4"
              />
              <source media="(min-width: 600px)" src={verticalImg} type="video/mp4"/>
            </video> :
            <picture>
              <source
                media="(max-width: 600px)"
                srcSet={verticalImgMobile}
              />
              <img src={verticalImg} alt="" />
            </picture>}
          <p className="caption" dangerouslySetInnerHTML={{__html: caption}}></p>
        </div>
      </div>
    </div>
  );
}
