import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '../';

export const Blurb = ({ text, link, buttonLabel, dropCap = true, bug, buttonVersion, alt }) => {
  const isArrow = buttonVersion === 'arrow';
  const dropCapClassName = alt ? 'drop-cap-alt' : 'drop-cap';

  return (
    <div className="blurb__container">
      <div className="blurb__container__inner">
        {bug && (
          <div className="blurb__container__bug">
            <img src={bug} alt="bug" />
          </div>
        )}
        <div
          className={`blurb__container__copy blurb__container__copy--${dropCap ? dropCapClassName : 'standard'}`}
          dangerouslySetInnerHTML={{__html: text}}
        />
        {link && (
          <a
            className={isArrow ? '' : 'blurb__container__link'}
            href={link}
          >
            <Button
              version={buttonVersion || 'rounded'}
              label={buttonLabel || 'Read More'}
              labelColor={isArrow ? '#ED5C30' : "#FFFFFF"}
              labelHoverColor={isArrow ? '#000' : undefined}
              hoverColor={isArrow ? 'transparent' : undefined}
              bgColor={isArrow ? '' : "#ED5C30"}
            />
          </a>
        )}
      </div>
    </div>
  );
};

Blurb.propTypes = {
  text: PropTypes.string,
  link: PropTypes.string,
  buttonLabel: PropTypes.string
}
