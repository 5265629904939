import React from 'react';
import PropTypes from 'prop-types';

export const Row = ({ children, addClass = '' }) => (
  <div className={`row__container ${addClass}`}>{children}</div>
);

Row.propTypes = {
  children: PropTypes.any,
  addClass: PropTypes.string
}
