import React, { Fragment, useRef } from 'react';
import PropTypes from 'prop-types';
import Slider from "react-slick";
import {
  Image,
  Button,
} from "../../";

export const Article = ({ articles, speed, slidesToShow, slidesToScroll, fade, label }) => {
  const settings = {
    className: "carousel__article",
    swipeToSlide: true,
    dots: false,
    infinite: true,
    fade: fade || false,
    speed: speed || 500,
    autoplaySpeed: 5000,
    slidesToShow: slidesToShow || 1,
    slidesToScroll: slidesToScroll || 1,
    centerMode: true,
    centerPadding: "40px",
    autoplay: true,
    arrows: false,
    pauseOnHover: true
  };

  // If no Carousel items, don't render a carousel component
  if (articles.length <= 0) return null;
  const sliderRef = useRef(null);

  const nextSlide = () => {
    sliderRef.current.slickNext()
  }

  const prevSlide = () => {
    sliderRef.current.slickPrev()
  }

  return (
    <Fragment>
      <Slider ref={sliderRef} {...settings}>
        {articles.map((article, i) => (
          <div
            key={`carousel-item-${i}`}
            className="carousel__item"
          >
            <div className="carousel__itemContents">
              <div className="carousel__itemContents__left">
                <Image image={article.image} />
              </div>
              <div className="carousel__itemContents__right">
                <div className="carousel__item__title">{article.title}</div>
                <div className="carousel__item__deck" dangerouslySetInnerHTML={{
                    __html: article.deck
                  }}/>
                <div>
                  <a href={article.url}><Button rounded bgColor="#CCCCCC" label={label || 'Button'}>READ MORE</Button></a>
                </div>
                <div className="carousel__arrowContainer">
                  <div onClick={nextSlide}>
                    {/* <Image image="https://images.fastcompany.net/image/upload/v1627993078/fcweb/arrow-left_pqay8w.svg"/> */}
                    <div>←</div>
                  </div>
                  <div onClick={prevSlide}>
                    {/* <Image image="https://images.fastcompany.net/image/upload/v1627993078/fcweb/arrow-right_kkxvu5.svg"/> */}
                    <div>→</div>
                  </div>
                </div>
              </div>
              
            </div>
            <div className="carousel__arrowContainer_nodesktop">
                <div onClick={nextSlide}>
                  {/* <Image image="https://images.fastcompany.net/image/upload/v1627993078/fcweb/arrow-left_pqay8w.svg"/> */}
                  <div>←</div>
                </div>
                <div onClick={prevSlide}>
                  {/* <Image image="https://images.fastcompany.net/image/upload/v1627993078/fcweb/arrow-right_kkxvu5.svg"/> */}
                  <div>→</div>
                </div>
              </div>
          </div>
        ))}
      </Slider>
    </Fragment>
  );
};

Article.propTypes = {
  articles: PropTypes.array.isRequired,
  speed: PropTypes.number,
  slidesToShow: PropTypes.number,
  slidesToScroll: PropTypes.number,
  fade: PropTypes.bool,
  label: PropTypes.string
};

Article.defaultProps = {
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};
