import React from 'react';
import PropTypes from 'prop-types';

export const Spacer = ({ height }) => {
  let componentStyles = {
    height: height || 0
  };

  return (
    <div
      className="spacer"
      style={componentStyles}
    />
  );
};

Spacer.propTypes = {
  height: PropTypes.string
}